import styled, { css } from 'styled-components';

import { media } from '../../../../styles/media';
import { Colors } from '../../../../styles/colors';

export const FieldWrapper = ({ children, noMargin, tips }: FieldWrapperProps) => {
  return (
    <FieldWrapperColumn $noMargin={noMargin}>
      <Field>{children}</Field>
      {tips && <Tips>{tips}</Tips>}
    </FieldWrapperColumn>
  );
};

const Field = styled.div`
  /* border: 1px green solid; */
  flex: 3;
  max-width: 900px;
  position: relative;
`;

const Tips = styled.div`
  display: none;
  /* border: 1px blue solid; */
  border-left: 4px solid ${Colors.Contrast};
  margin-left: 64px;
  padding: 4px 0 4px 8px;
  flex: 1;
  margin-block: auto;

  ul {
    padding: 0 0 0 16px;
    list-style: none;
  }

  ${media.from_tablet`
    display: block;
  `}
`;

const FieldWrapperColumn = styled.div<{ $noMargin?: boolean }>`
  /* border: 1px solid red; */
  display: flex;
  // this is causing issue
  /* flex-basis: 600px; */
  margin-bottom: 16px;

  ${({ $noMargin }) =>
    !$noMargin &&
    css`
      margin-bottom: 16px;

      ${media.from_tablet`
        margin-bottom: 32px;
      `}

      ${media.from_laptop`
        margin-bottom: 40px;
      `}
    `};
`;

type FieldWrapperProps = {
  children: React.ReactNode;
  tips?: React.ReactNode;
  noMargin?: boolean;
};
